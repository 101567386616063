
import { HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export abstract class RequestBuilder {
  private API_URL = environment.TF_API;
  protected api: string = '';

  getUrl(uri?: string , id?: string ): string {
    let u = uri;
    if (this.api) {
      if(u){
        if (id) {
          u = `${uri}/${id}`;
        }
        return `${this.API_URL}/${this.api}/${u}`;
      } else {
        if (id) {
          return `${this.API_URL}/${this.api}/${id}`;
        } else {
          return `${this.API_URL}/${this.api}`;
        }
      }
    } else {
      return `${this.API_URL}/${u}`;
    }
  }
 
  QueryParams(paremetros: any): HttpParams {
    let params = new HttpParams();
    Object.keys(paremetros).forEach( p => {
      if (paremetros[p] && paremetros[p] !== '') {
        switch (paremetros[p].constructor.name) {
          case 'String':
            params = params.append(p, paremetros[p]);
          break;
          case 'Date':
            params = params.append(p, paremetros[p]);
          break;
          default:
            params = params.append(p, paremetros[p].toString());
          break;
        }
      }
    });
    return params;
  }

}
