
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


import { RequestBuilder } from '../request-builder';
import { Store } from '../../services/store';
import { LoginItem } from '../../interfaces/Auth/loginItem.model';
import { UserAuth } from '../../interfaces/Auth/userAuth.model';

@Injectable({providedIn:'root'})
export class AuthService extends RequestBuilder{

  public token?: string;
  protected api = 'auth';

  constructor(private http: HttpClient) {
    super();
    this.token = Store.hasToken();
  }

  isAuth(): boolean {
     return  Boolean(this.token);
  }

  login(email: string, password: string): Observable<boolean> {
    const url = this.getUrl('login');
    const params = {email, password };

    return this.http.post(url, params).pipe(
      map( response => {
        const login = (response as LoginItem);
        if (login.token ) {

          this.token = login.token;
          Store.set('currentUser', login);
          this.getUserById(login.id).subscribe({
            next: (response) => {
              Store.set('type', response.type);
            },
            error: (err) => {
              console.log("erro", err);
            },
          });

          return true;
        } else {
          return false;
        }
      }));
  }

  logout(): void {
    this.token = undefined;
    Store.set('currentUser', null);
  }

  postResetpassword (email: string) {
    const url = this.getUrl('resetpassword');
    return this.http.post (url , { email });
  }

  getConfirmReset (token: string) {
    const url = this.getUrl('confirmreset');
    return this.http.post(url, { token });
  }

  getUserById(id: number, token?: string): Observable<UserAuth> {
    const url = this.getUrl('users');
    return this.http.get<UserAuth>(`${url}/${id}`);
  }



}
