import { VexRoutes } from '@vex/interfaces/vex-route.interface';
import { LoginComponent } from './pages/auth/login/login.component';
import { AuthGuard } from './shared/guards/auth.guard';

export const appRoutes: VexRoutes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '',
    loadChildren: () => import('./pages/pages.routes').then(m => m.PAGES_ROUTES),
    canActivate : [AuthGuard],
  },
];
