import { Injectable, NgModule, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import {
 HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, HttpErrorResponse, HttpResponse, HttpClientModule
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoaderService } from './loader.service';
import { Store } from "../shared/services/store";

@Injectable()

export class HttpsRequestInterceptor implements HttpInterceptor {
  excesscao = [
  '/auth/login'
  ]
  constructor(private injector: Injector, private router: Router, private loader: LoaderService) { }

  hasExcessao(s: string): boolean {
    let result = false;
    this.excesscao.forEach( item => {
      if (s.indexOf(item) > -1 ) {
        result = true;
        return false;
      }
    });
    return result;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
    if (!this.hasExcessao(req.url)) {
      this.loader.show();
    }
    const dupReq = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + Store.getToken())
    });
    return next.handle(dupReq).pipe(tap(
        response => {
          if ( response instanceof HttpResponse) {
            this.loader.hide();
          }

        },
        error => {
          this.loader.hide();
          if (error instanceof HttpErrorResponse) {
            switch(error.status) {
              case 403:
                this.router.navigate(['login']);
                break;
            }
        }
      }));
  }
}


@NgModule({
imports: [
  HttpClientModule
],
providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpsRequestInterceptor,
    multi: true
  }]
})
export class Interceptor {}