import { Injectable } from '@angular/core';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { NavigationItem } from './navigation-item.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationLoaderService {
  private readonly _items: BehaviorSubject<NavigationItem[]> =
    new BehaviorSubject<NavigationItem[]>([]);

  get items$(): Observable<NavigationItem[]> {
    return this._items.asObservable();
  }

  constructor(private readonly layoutService: VexLayoutService) {
    this.loadNavigation();
  }

  loadNavigation(): void {
    this._items.next([
     {
        type: 'subheading',
        label: 'Dashboards',
        children: [
          {
            type: 'link',
            label: 'Home',
            route: '/home',
            icon: 'mat:house',
            routerLinkActiveOptions: { exact: true }
          }
        ]
      },
     /* {
        type: 'subheading',
        label: 'Sistema',
        children: [          
          {
            type: 'dropdown',
            label: 'Usuários',
            icon: 'mat:people',
            children: [
              {
                type: 'link',
                label: 'Lista com filtros',
                route: '/usuarios/lista',
              },
              {
                type: 'link',
                label: 'Cadastro',
                route: '/apps/help-center/pricing'
              },
            ]
          },
          {
            type: 'dropdown',
            label: 'Perfis',
            icon: 'mat:account_box',
            children: [
              {
                type: 'link',
                label: 'Lista com filtros',
                route: '/apps/help-center/getting-started'
              },
              {
                type: 'link',
                label: 'Cadastro',
                route: '/apps/help-center/pricing'
              },
            ]
          },
        ]
      }, */
      {
        type: 'subheading',
        label: 'Apps',
        children: [ 
          {
            type: 'dropdown',
            label: 'ACP',
            icon: 'mat:speaker_notes',
            children: [
              {
                type: 'link',
                label: 'Base TF',
                route: '/acp/list'
              },                        
              {
                type: 'link',
                label: 'Cadastro de Produtos',
                route: '/acp/cadastro-produto'
              },                        
              {
                type: 'link',
                label: 'Importar Produtos',
                route: '/acp/importar-produtos'
              },                     
            ]
          },
          {
            type: 'dropdown',
            label: 'Usuários',
            icon: 'mat:show_chart',
            children: [
              {
                type: 'link',
                label: 'Lista com filtros',
                route: '/users/list'
              },
              {
                type: 'link',
                label: 'Incluir',
                route: '/users/create'
              },
            ]
          },
        ]
      },
      {
        type: 'subheading',
        label: 'Tabelas Padrão',
        children: [        
          {
            type: 'dropdown',
            label: 'IPI',
            icon: 'mat:show_chart',
            children: [
              {
                type: 'link',
                label: 'Lista com filtros',
                route: '/ipi/list'
              },          
              {
                type: 'link',
                label: 'Incluir',
                route: '/ipi/incluir'
              },
            ]
          },  
          {
            type: 'dropdown',
            label: 'NCM',
            icon: 'mat:show_chart',
            children: [
              {
                type: 'link',
                label: 'Lista com filtros',
                route: '/ncm/list'
              },          
              /*{
                type: 'link',
                label: 'Importações',
                route: '/ncm/ncm-importacao'
              },*/
            ]
          },      
          {
            type: 'dropdown',
            label: 'CEST / MVA',
            icon: 'mat:show_chart',
            children: [
              {
                type: 'link',
                label: 'Lista com filtros',
                route: '/cest-mva/list'
              },         
              {
                type: 'link',
                label: 'Incluir',
                route: '/cest-mva/incluir'
              },
            ]
          },     
          {
            type: 'dropdown',
            label: 'ICMS / CFOP',
            icon: 'mat:show_chart',
            children: [
              {
                type: 'link',
                label: 'Lista com filtros',
                route: '/icms/icms-list'
              },          
              {
                type: 'link',
                label: 'Validações',
                route: '/icms/validacoes-list'
              },          
              {
                type: 'link',
                label: 'Incluir',
                route: '/icms/icms-incluir'
              },
            ]
          },     
          {
            type: 'dropdown',
            label: 'PIS / COFINS',
            icon: 'mat:show_chart',
            children: [
              {
                type: 'link',
                label: 'Lista com filtros',
                route: '/pis-cofins/list'
              },          
              {
                type: 'link',
                label: 'Validações',
                route: '/pis-cofins/validacoes/list'
              },          
              {
                type: 'link',
                label: 'Incluir',
                route: '/pis-cofins/incluir'
              },
            ]
          },     
          {
            type: 'dropdown',
            label: 'Natureza da Receita',
            icon: 'mat:show_chart',
            children: [
              {
                type: 'link',
                label: 'Lista com filtros',
                route: '/natureza-receita/list'
              },          
              {
                type: 'link',
                label: 'Incluir',
                route: '/natureza-receita/incluir'
              },
            ]
          },         
          {
            type: 'dropdown',
            label: 'Matriz Tributária',
            icon: 'mat:show_chart',
            children: [
              {
                type: 'link',
                label: 'Lista com filtros',
                route: '/matriz-tributaria/list'
              },         
              {
                type: 'link',
                label: 'Incluir',
                route: '/matriz-tributaria/incluir'
              },
              {
                type: 'link',
                label: 'Revisor',
                route: '/matriz-tributaria/revisor'
              },
            ]
          },     

        ]
      }
    ]);
  }
}
