<div class="w-full h-full bg-pattern flex flex-col items-center justify-center">
  <div @fadeInUp class="card overflow-hidden w-full max-w-md">
    <div class="p-6 pb-0 flex flex-col items-center justify-center">
      <div class="fill-current text-center">
        <img class="w-16" src="assets/img/logo/logo.svg" />
      </div>
    </div>

    <div class="text-center mt-4">
      <h2 class="title m-0">Tributo Fácil</h2>
      <h4 class="body-2 text-secondary m-0">
        Faça login com suas credenciais abaixo.
      </h4>
    </div>

    <div [formGroup]="loginForm" class="p-6 flex flex-col gap-4">
      <div class="flex flex-col">
        <mat-form-field class="flex-1">
          <mat-label>E-mail</mat-label>
          <input formControlName="email" matInput required />
          <mat-error *ngIf="loginForm.controls.email.hasError('required')"
          >We need an email address to log you in
          </mat-error>
        </mat-form-field>
        <mat-form-field class="flex-1">
          <mat-label>Senha</mat-label>
          <input
            [type]="inputType"
            formControlName="password"
            matInput
            required />
          <button
            (click)="toggleVisibility()"
            mat-icon-button
            matIconSuffix
            matTooltip="Toggle Visibility"
            type="button">
            <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
            <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
          </button>
          <mat-hint>Clique no olho para alternar a visibilidade</mat-hint>
          <mat-error *ngIf="loginForm.controls.password.hasError('required')"
          >Precisamos de uma senha para fazer seu login
          </mat-error>
        </mat-form-field>
      </div>

      <div class="flex items-center justify-between">
        <mat-checkbox class="caption" color="primary">Remember Me</mat-checkbox>
        <a [routerLink]="['/forgot-password']" class="caption"
        >esqueceu sua senha?</a
        >
      </div>

      <button (click)="send()" color="primary" mat-raised-button type="button">
        Entrar
      </button>

      
    </div>
  </div>
</div>
